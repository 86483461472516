import { Outlet } from '@remix-run/react'
import { AppFooter, AppHeader } from '~/components'

export default function AppLayoutPage() {
  return (
    <div className="flex min-h-screen flex-col gap-2 overflow-auto bg-slate-200">
      <AppHeader />

      <main className="flex h-full max-w-full flex-1 flex-col px-2 md:container">
        <Outlet />
      </main>

      <AppFooter />
    </div>
  )
}
